
	import DateRangePicker from 'vue2-daterange-picker'
	import { ValidationObserver, ValidationProvider } from 'vee-validate'
	let timer = null

	const initialState = () => ({
		variations: [],
		categories: [{
			id: null,
			name: 'all'
		}],
		items: {
			data: [],
			pagination: {}
		},
		reportFilters: {
			dateRange: {
				startDate: null,
				endDate: null
			},
			category: null,
			variation: null
		},
		showAdvanceOrderReportModal: false
	})

	export default {
		components: {
			DateRangePicker,
			ValidationObserver,
			ValidationProvider
		},
		props: {
			type: {
				type: String,
				required: true
			}
		},
		data () {
			return {
				loading: {
					orders: false,
					channels: false,
					cancelReasons: false,
					deliveryServices: false,
					orderCancellation: false,
					employees: false,
					report: false
				},
				filters: {
					platform: this.type,
					channel_id: null,
					order_interim_state: 'active',
					channel: null,
					order_type: null,
					payment_method: null,
					transporter_id: null
				},
				orderTypes: [
					{
						text: 'order type',
						value: null
					},
					{
						text: 'delivery',
						value: 'delivery'
					},
					{
						text: 'pickup',
						value: 'pickup'
					}
				],
				paymentMethods: [
					{
						text: 'payment method',
						value: null
					}
				],
				orderStates: [
					{
						text: 'order status',
						value: null
					},
					{
						text: 'active',
						value: 'active'
					},
					{
						text: 'pending',
						value: 'placed'
					},
					{
						text: 'confirm',
						value: 'confirmed',
						pluralizationIndex: 2
					},
					{
						text: 'ready',
						value: 'ready'
					},
					{
						text: 'assign',
						value: 'assigned',
						pluralizationIndex: 2
					},
					{
						text: 'dispatch',
						value: 'dispatched',
						pluralizationIndex: 2
					},
					{
						text: 'deliver',
						value: 'delivered',
						pluralizationIndex: 2
					},
					{
						text: 'payment authorized',
						value: 'payment_authorized'
					},
					{
						text: 'complete',
						value: 'completed',
						pluralizationIndex: 2
					},
					{
						text: 'cancel',
						value: 'cancelled',
						pluralizationIndex: 2
					}
				],
				channels: [],
				orderStateAggregate: [],
				badges: {
					placed: 'warning',
					confirmed: 'info',
					ready: 'primary',
					assigned: 'secondary',
					dispatched: 'dark',
					delivered: 'light',
					completed: 'success',
					cancelled: 'danger'
				},
				dateRange: {
					startDate: this.type === 'advance' ? this.$moment().startOf('day').toDate() : null,
					endDate: this.type === 'advance' ? this.$moment().endOf('day').toDate() : null
				},
				orders: {
					data: [],
					total: 0,
					offset: 0
				},
				selectedOrder: null,
				receiptCode: '',
				refCode: '',
				orderCancellation: {
					show: false,
					reasons: [],
					selectedReason: null,
					otherReason: ''
				},
				delivery: {
					service: null,
					transporter: null
				},
				showDeliveryModal: false,
				deliveryServices: [],
				employees: [],
				showPaymentModal: false,
				selectedPaymentMethod: null,
				keystrokes: '',
				itemFields: [
					{
						key: 'item_name',
						label: 'item name'
					},
					{
						key: 'category',
						label: 'category'
					},
					{
						key: 'quantity',
						label: 'quantity'
					}
				],
				orderPreparation: {
					show: false,
					minutes: null,
					confirmed: false
				},
				...initialState()
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			locale () {
				return this.$store.state.locale
			},
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			settings () {
				return this.$store.state.settings
			},
			device () {
				return this.$store.state.device
			},
			primaryDevice () {
				return this.$store.state.primaryDevice
			},
			partner () {
				return this.$store.state.partner
			},
			referrer () {
				return window.location.origin
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			parentMerchant () {
				return this.$store.state.merchant
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			employee () {
				return this.$store.state.employee
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			employeeShift () {
				return this.$store.state.employeeShift
			},
			cashDrawerShift () {
				return this.$store.state.cashDrawerShift
			},
			newOnlineOrdersCount: {
				get () {
					return this.$store.state.newOnlineOrdersCount
				},
				set (count) {
					this.$store.commit('setState', {
						key: 'newOnlineOrdersCount',
						value: count,
						save: true
					})
				}
			},
			advanceOrdersCount: {
				get () {
					return this.$store.state.advanceOrdersCount
				},
				set (value) {
					this.$store.commit('setState', { key: 'advanceOrdersCount', value })
				}
			},
			isTestingDevice () {
				return this.$store.getters.isTestingDevice
			},
			computedPrice () {
				const price = {
					subtotal: this.selectedOrder.sub_total || 0,
					tax: this.selectedOrder.total_tax || 0
				}

				if (
					this.selectedOrder && (
						!this.selectedOrder.custom_attributes.tax_calculation_phase ||
						this.selectedOrder.custom_attributes.tax_calculation_phase === 'before_discount'
					) && this.settings.general.include_tax_in_subtotal
				) {
					price.subtotal -= this.selectedOrder.discounted_amount
					price.tax -= this.selectedOrder.discounted_tax
				}

				return price
			}
		},
		watch: {
			reportFilters: {
				handler () {
					if (document.querySelectorAll('.modal.show').length === 1) {
						this.getReports()
					}
				},
				deep: true
			}
		},
		async beforeMount () {
			const paymentMethods = await this.$bridge.getPaymentMethods(this.deviceId, '')

			this.getOrders({ only_active: 1 })
			this.getOrderStateAggregate()
			this.updatePendingOrders()
			this.processAutoAcceptedOrders()
			this.paymentMethods = this.paymentMethods.concat((
				typeof paymentMethods === 'string' ? JSON.parse(paymentMethods) : paymentMethods
			).map((pm) => {
				return {
					text: pm.name,
					value: pm.slug
				}
			}))
		},
		mounted () {
			if (this.type === 'online') {
				this.newOnlineOrdersCount = 0
			}

			if (this.type === 'advance') {
				const toastElement = document.getElementById('advance-order-toast')

				if (toastElement) {
					this.$bvToast.hide(toastElement.id)
				}
			}

			this.$refs.ordersBlock.addEventListener('scroll', this.loadMoreOrders)
			this.$root.$on('online-order-status-change', this.onlineOrderStatusChange)
			this.$root.$on('get-online-orders', () => {
				this.getOrders({})
				this.getOrderStateAggregate()
				this.updatePendingOrders()
				this.processAutoAcceptedOrders()
			})
		},
		destroyed () {
			delete this.$root._events['online-order-status-change']
			delete this.$root._events['get-online-orders']
		},
		methods: {
			processOrder ($event, orderId, status) {
				clearTimeout(timer)
				timer = setTimeout(async () => {
					const order = this.orders.data.find(o => o.id === orderId)

					if (status === 'confirmed') {
						if (order.custom_attributes.tables && this.appVersionNumber >= 4030) {
							let tables = await this.$bridge.getTables(
								this.deviceId,
								this.locationId,
								this.objToJson({ id: order.custom_attributes.tables, status: 'blocked' })
							)

							tables = typeof tables === 'string' ? JSON.parse(tables) : tables
							tables = tables.data || tables

							if (tables.length) {
								const response = await this.$swal({
									title: this.$t('areYouSure'),
									text: this.$tc('blockedTableWarning', tables.length === 1 ? 1 : 2, [tables.map(t => t.name).join(', ')]),
									icon: 'warning',
									buttons: [this.$tc('no', 1), this.$t('yes')]
								})

								if (!response) {
									return 0
								}
							}
						}

						const itemIds = order.items.reduce((ids, i) => {
							if (!ids.includes(i.id)) {
								ids.push(i.id)
							}

							return ids
						}, [])
						let localItemVariations = await this.$bridge.getItemVariations(this.deviceId, this.objToJson({
							id: itemIds
						}))

						localItemVariations = (typeof localItemVariations === 'string'
							? JSON.parse(localItemVariations)
							: localItemVariations)

						localItemVariations = localItemVariations.data || localItemVariations

						if (localItemVariations.length < itemIds.length) {
							const missingItems = order.items.reduce((items, item) => {
								if (localItemVariations.findIndex(i => i.id === item.id) === -1) {
									items.push(item.name)
								}

								return items
							}, [])

							return this.$swal({
								title: this.$t('itemSyncRequiredError.title'),
								text: this.$t('itemSyncRequiredError.text', [
									missingItems.join(', '),
									this.$t(missingItems.length > 1 ? 'are' : 'is')
								]),
								icon: 'error',
								buttons: [this.$t('no'), this.$t('itemSyncRequiredError.yesButton')]
							}).then((response) => {
								if (response) {
									this.syncAll()
										.then(() => this.$refs.statusUpdateBtn?.click())
										.finally(() => this.$store.commit('resetProgress'))
								}
							})
						}

						this.receiptCode = order.receipt_code || await this.$store.dispatch('generateReceiptCode')
						this.refCode = order.ref_code || (await this.$store.dispatch('generateRefCode')).code

						if (!this.receiptCode) {
							return this.$swal({
								title: this.$t('primaryDeviceError.title'),
								text: this.$t('primaryDeviceError.text'),
								icon: 'error',
								buttons: [this.$tc('cancel', 1), this.$t('connect')]
							}).then((response) => {
								if (response) {
									this.processOrder($event, orderId, status)
								}
							})
						}
					}

					this.updateOrderStatus($event, orderId, status)
				}, 500)
			},
			clearFilters (refresh = true) {
				this.filters = {
					platform: this.type,
					channel_id: null,
					order_interim_state: null,
					channel: null,
					order_type: null,
					payment_method: null,
					transporter_id: null
				}
				this.dateRange = { startDate: null, endDate: null }

				if (refresh) {
					this.getOrders({ only_active: 1 })
					this.getOrderStateAggregate()
				}
			},
			getOrders (filters) {
				this.loading.orders = true
				this.$axios.get('/api/online-orders/list', {
					params: Object.assign({ ...this.filters }, {
						location_id: this.locationId,
						only_active: this.filters.order_interim_state === 'active' ? 1 : 0,
						order_interim_state: this.filters.order_interim_state === 'active' ? null : this.filters.order_interim_state,
						from_date: this.dateRange.startDate
							? this.$moment(this.dateRange.startDate).startOf('day').utc().format('DD-MM-YYYY HH:mm:ss')
							: null,
						to_date: this.dateRange.endDate
							? this.$moment(this.dateRange.endDate).endOf('day').utc().format('DD-MM-YYYY HH:mm:ss')
							: null
					}, filters)
				}).then((response) => {
					if (response.status === 200) {
						if (filters.page) {
							this.orders.data = this.orders.data.concat(response.data.data.orders)
						} else {
							this.orders.data = response.data.data.orders
						}

						this.orders.total = response.data.data.pagination.total
						this.orders.offset = response.data.data.pagination.current_page

						let selectedOrder

						if (this.selectedOrder) {
							selectedOrder = this.orders.data.find((o) => {
								// eslint-disable-next-line eqeqeq
								return o.id == this.selectedOrder.id
							})
						}

						this.selectOrder(selectedOrder || response.data.data.orders[0])
					}
				}).catch(console.error).finally(() => {
					this.loading.orders = false
				})
			},
			getOrderStateLabel (state) {
				const orderState = this.orderStates.find(s => s.value === state)

				return (orderState ? this.$tc(orderState.text, orderState.pluralizationIndex || 1) : state)?.replace(/_/g, ' ')
			},
			async updatePendingOrders () {
				let pendingDbOrders = await this.$store.dispatch('bridgeCall', {
					methodName: 'getOrders',
					args: [this.deviceId, this.objToJson({ status: ['draft', 'pending'] })]
				})

				pendingDbOrders = (
					typeof pendingDbOrders === 'string' ? JSON.parse(pendingDbOrders) : pendingDbOrders
				).data

				if (pendingDbOrders.length) {
					this.$axios.get('/api/online-orders/state-list', {
						params: {
							order_ids: pendingDbOrders.map(o => o.id)
						}
					}).then(async (response) => {
						const statusMapping = {
							refunded: 'cancelled',
							payment_authorized: 'billed'
						}

						for (const id in response.data.data.orders) {
							if (['completed', 'cancelled', 'refunded', 'payment_authorized'].includes(response.data.data.orders[id])) {
								const order = {
									id: this.getUniqueId(id),
									status: statusMapping[response.data.data.orders[id]] || response.data.data.orders[id],
									updated_at: new Date()
								}
								// eslint-disable-next-line eqeqeq
								const dbOrder = pendingDbOrders.find(o => o.id == order.id)

								if (['restaurant', 'qsr'].includes(this.merchant.businessType) && order.status === 'cancelled') {
									const kotItemsToPrint = []

									order.kot_items = dbOrder.kot_items.map((i) => {
										const previousStatus = i.status
										const statusObj = {
											status: 'cancelled',
											status_history: i.status_history.concat([{
												status: 'cancelled',
												created_at: order.updated_at
											}])
										}

										if (previousStatus !== 'dispatched' && !(dbOrder.custom_attributes.platform === 'store' && previousStatus === 'pending')) {
											kotItemsToPrint.push(Object.assign({}, i, statusObj))
										}

										return {
											id: i.id,
											status: statusObj.status,
											status_history: this.objToJson(statusObj.status_history)
										}
									})

									if (kotItemsToPrint.length) {
										window.printKot({
											...dbOrder,
											items: this.groupBy(kotItemsToPrint, 'kot_device_id')
										})
									}
								}

								if (dbOrder.tables.length && ['completed', 'cancelled'].includes(order.status)) {
									order.tables = dbOrder.tables.map((table) => {
										return {
											...table,
											custom_attributes: this.objToJson(table.custom_attributes),
											updated_at: order.updated_at
										}
									})
								}

								await this.$store.dispatch('bridgeCall', {
									methodName: 'insert',
									args: [
										'Order',
										this.bridgeName === 'ANDROID' ? this.objToJson(order) : order,
										true
									]
								})

								await this.$store.dispatch('updateTableStatus', order.tables?.map(t => t.id))
							}
						}
					}).catch(console.error)
				}
			},
			async processAutoAcceptedOrders () {
				try {
					const response = await this.$axios.get('/api/online-orders/list', {
						params: {
							location_id: this.locationId,
							order_accepted_from: 'online',
							order_interim_state: 'confirmed',
							from_date: this.$moment().startOf('day').utc().format('DD-MM-YYYY HH:mm:ss'),
							to_date: this.$moment().endOf('day').utc().format('DD-MM-YYYY HH:mm:ss')
						}
					})

					if (response.status === 200) {
						for (const order of response.data.data.orders) {
							let dbOrder = await this.$bridge.getOrders(this.deviceId, this.objToJson({
								id: order.id.toString()
							}))

							dbOrder = (typeof dbOrder === 'string' ? JSON.parse(dbOrder) : dbOrder).data[0]

							if (!dbOrder) {
								await this.acceptOnlineOrder(order)
							}
						}
					}
				} catch (err) {
					console.error(err)
				}
			},
			getOrderStateAggregate () {
				this.$axios.get('/api/online-orders/state-aggregate', {
					params: Object.assign({ ...this.filters }, {
						location_id: this.locationId,
						order_interim_state: this.filters.order_interim_state === 'active' ? null : this.filters.order_interim_state,
						from_date: this.dateRange.startDate
							? this.$moment(this.dateRange.startDate).startOf('day').utc().format('DD-MM-YYYY HH:mm:ss')
							: null,
						to_date: this.dateRange.endDate
							? this.$moment(this.dateRange.endDate).endOf('day').utc().format('DD-MM-YYYY HH:mm:ss')
							: null
					})
				}).then((response) => {
					if (response.status === 200) {
						this.orderStateAggregate = response.data.data.order_state_aggregate

						if (
							this.type === 'advance' &&
							this.$moment().startOf('day').isSame(this.$moment(this.dateRange.startDate).startOf('day')) &&
							this.$moment().endOf('day').isSame(this.$moment(this.dateRange.endDate).endOf('day'))
						) {
							this.advanceOrdersCount = response.data.data.order_state_aggregate
								.find(s => s.state === 'placed')?.count || 0
						}

						return
					}

					this.orderStateAggregate = []
				}).catch((err) => {
					console.error(err)
					this.orderStateAggregate = []
				})
			},
			getStateAggregate (state) {
				const aggregateState = this.orderStateAggregate.find(s => s.state === state)

				return aggregateState ? aggregateState.count : 0
			},
			getChannels (query) {
				this.loading.channels = true
				this.$axios.get('/api/online-orders/platforms', {
					params: {
						query,
						platform_type: 'store'
					}
				}).then((response) => {
					this.channels = response.data.data.platforms
				}).catch(console.error).finally(() => {
					this.loading.channels = false
				})
			},
			async selectOrder (order, $event) {
				if (order) {
					let tables = order.custom_attributes.tables?.length
						? await this.$bridge.getTables(
							this.deviceId,
							this.locationId,
							this.objToJson({ id: order.custom_attributes.tables })
						)
						: []

					this.receiptCode = ''
					tables = typeof tables === 'string' ? JSON.parse(tables) : tables
					order.tables = tables.data || tables

					if ($event) {
						document.querySelectorAll('.list-group-item').forEach((i) => {
							if (i === $event.currentTarget) {
								i.classList.add('active')
							} else {
								i.classList.remove('active')
							}
						})
					}

					this.selectedOrder = order
				} else {
					this.selectedOrder = null
				}
			},
			searchOrders () {
				clearTimeout(timer)
				timer = setTimeout(() => {
					this.getOrders({})
					this.getOrderStateAggregate()
				}, 500)
			},
			loadMoreOrders ($event) {
				if (this.orders.data.length < this.orders.total && Math.round($event.currentTarget.scrollTop) > (
					$event.currentTarget.scrollHeight - $event.currentTarget.offsetHeight
				) - 10 && !this.loading.orders) {
					this.getOrders({ page: this.orders.offset + 1 })
				}
			},
			async updateOrderStatus ($event, orderId, status) {
				const date = new Date()
				const data = {
					state: status,
					employee_id: this.employee.id,
					shift_code: this.employeeShift.shift_code
				}

				const kotItemsToPrint = []
				let orderKotItems = []
				let dbOrder = null

				if (['confirmed', 'cancelled'].includes(status) && this.type === 'online') {
					this.newOnlineOrdersCount = 0
				}

				if (status === 'confirmed') {
					data.ref_code = this.refCode
					data.receipt_code = this.receiptCode

					if (this.selectedOrder.custom_attributes?.prep_time?.adjustable) {
						if (this.orderPreparation.minutes || this.orderPreparation.minutes === 0) {
							data.prep_time_mins = this.orderPreparation.minutes
						} else {
							return (this.orderPreparation.show = true)
						}
					}
				}

				if (this.orderCancellation.selectedReason) {
					data.message = this.orderCancellation.selectedReason.slug === 'other'
						? this.orderCancellation.otherReason
						: this.orderCancellation.selectedReason.name
				}

				if (status === 'assigned') {
					if (!this.delivery.service || (this.delivery.service.slug === 'posbytz' && !this.delivery.transporter)) {
						this.showDeliveryModal = true
						setTimeout(() => {
							document.querySelector('#assign-delivery-service-modal .vs__search')?.focus()
						}, 500)

						return
					} else {
						Object.assign(data, {
							delivery_service: this.delivery.service.slug,
							transporter: this.delivery.transporter
								? {
									id: this.delivery.transporter.id,
									name: this.delivery.transporter.name,
									phone: {
										number: this.delivery.transporter.phone,
										code: this.delivery.transporter.calling_code
									}
								}
								: null
						})
					}
				}

				if (status === 'completed' && ((this.type === 'advance' && this.selectedOrder.due_amount > 0) ||
					(this.type === 'online' && this.selectedOrder.order_type === 'pickup' && this.selectedOrder.payment[0].method === 'cod'))) {
					if (!this.selectedPaymentMethod) {
						this.showPaymentModal = true

						return
					} else {
						data.payment = {
							slug: this.selectedPaymentMethod,
							amount: this.type === 'advance' ? this.selectedOrder.due_amount : this.selectedOrder.total
						}
					}
				}

				if (status !== 'cancelled' && this.$refs.cancelBtn) {
					this.$refs.cancelBtn.disabled = true
				}

				if (['restaurant', 'qsr'].includes(this.merchant.businessType)) {
					dbOrder = await this.$store.dispatch('bridgeCall', {
						methodName: 'getOrders',
						args: [this.deviceId, this.objToJson({ id: orderId.toString() })]
					})
					dbOrder = (typeof dbOrder === 'string' ? JSON.parse(dbOrder) : dbOrder).data[0]

					if (dbOrder) {
						orderKotItems = status === 'cancelled'
							? dbOrder.kot_items.map((i) => {
								const previousStatus = i.status

								i.status = 'cancelled'
								i.status_history = i.status_history.concat([{
									status: 'cancelled',
									created_at: new Date()
								}])

								if (previousStatus !== 'dispatched') {
									kotItemsToPrint.push(i)
								}

								return i
							})
							: dbOrder.kot_items

						data.kot_items = orderKotItems
					}
				}

				$event.target.disabled = true
				$event.target.classList.add('running')

				try {
					const response = await this.$store.dispatch('updateOnlineOrderStatus', { orderId, data })

					if (response.status === 200) {
						this.getOrderStateAggregate()

						const order = response.data.data.orders

						if (this.selectedOrder.tables?.length) {
							order.tables = this.selectedOrder.tables
						}

						if (status === 'confirmed') {
							this.acceptOnlineOrder(JSON.parse(this.objToJson(order)))
						} else {
							const localOrder = await this.formatOnlineOrder(order)

							localOrder.status = status === 'cancelled'
								? 'cancelled'
								: (status === 'completed' || (order.payment[0].method !== 'cod' && order.custom_attributes.platform !== 'advance' && status === 'dispatched'))
									? 'completed'
									: 'pending'

							if (
								dbOrder &&
								['restaurant', 'qsr'].includes(this.merchant.businessType) &&
								!(this.selectedOrder.interim_state === 'placed' && status === 'cancelled')
							) {
								localOrder.kot_items = orderKotItems

								if (kotItemsToPrint.length) {
									window.printKot({
										...localOrder,
										items: this.groupBy(kotItemsToPrint, 'kot_device_id')
									})
								}
							}

							if (localOrder.tables.length && ['completed', 'cancelled'].includes(localOrder.status)) {
								localOrder.tables = localOrder.tables.map((table) => {
									return {
										...table,
										updated_at: localOrder.updated_at
									}
								})
							}

							if (
								dbOrder &&
								localOrder.custom_attributes.platform === 'advance' &&
								['completed', 'cancelled'].includes(localOrder.status) &&
								dbOrder.due_amount > 0
							) {
								localOrder.customers[0].credit_limit = dbOrder.customer.credit_limit
								localOrder.customers[0].credit = dbOrder.customer.credit - localOrder.status === 'cancelled'
									? localOrder.order_payments.find(p => p.slug === 'credit')?.amount || 0
									: 0
								localOrder.customers[0].debit = dbOrder.customer.debit + localOrder.status === 'cancelled'
									? 0
									: localOrder.order_payments[localOrder.order_payments.length - 1].amount

								if (this.appVersionNumber >= 4110 || (this.bridgeName === 'ANDROID' && this.appVersionNumber >= 4029)) {
									localOrder.credits.push({
										id: this.getUniqueId(),
										merchant_id: this.merchant.id,
										device_id: this.deviceId,
										employee_id: this.employee.id,
										employee_shift_id: this.employeeShift.id,
										customer_id: dbOrder.customer.id,
										order_id: localOrder.id,
										credit_code: `${this.deviceId}${date.valueOf()}`,
										amount: localOrder.status === 'cancelled'
											? localOrder.order_payments.find(p => p.slug === 'credit')?.amount || 0
											: localOrder.order_payments[localOrder.order_payments.length - 1].amount,
										payment_method: localOrder.order_payments[localOrder.order_payments.length - 1].slug,
										type: localOrder.status === 'cancelled' ? 'void' : 'debit',
										notes: '',
										custom_attributes: '{}',
										created_at: date,
										updated_at: date
									})
								}

								this.printReceipt({ ...localOrder })
							}

							this.saveOnlineOrder(localOrder, dbOrder)
						}

						// eslint-disable-next-line eqeqeq
						const orderIndex = this.orders.data.findIndex(o => o.id == orderId)

						if (orderIndex !== -1) {
							this.$set(this.orders.data, orderIndex, order)
						}

						if (this.selectedOrder.id === order.id) {
							this.selectedOrder = order
						}
					}
				} catch (err) {
					console.error(err)
				} finally {
					if (status !== 'cancelled' && this.$refs.cancelBtn) {
						this.$refs.cancelBtn.disabled = false
					}

					$event.target.disabled = false
					$event.target.classList.remove('running')
					this.delivery = { service: null, transporter: null }
					this.selectedPaymentMethod = null
					this.orderPreparation.minutes = null
				}
			},
			initOrderCancellation () {
				this.orderCancellation.show = true
				setTimeout(() => {
					document.querySelector('#order-cancellation-modal .vs__search')?.focus()
				}, 500)
			},
			async cancelOrder ($event) {
				if (await this.$refs.orderCancellationValidator.validate()) {
					this.loading.orderCancellation = true
					this.updateOrderStatus($event, this.selectedOrder.id, 'cancelled').then(() => {
						this.orderCancellation.show = false
					}).finally(() => {
						this.loading.orderCancellation = false
					})
				}
			},
			resetOrderCancellationReason () {
				this.orderCancellation.selectedReason = null
				this.orderCancellation.otherReason = ''
			},
			onlineOrderStatusChange (order) {
				// eslint-disable-next-line eqeqeq
				const orderIndex = this.orders.data.findIndex(o => o.id == order.id)
				const orders = this.orders.data

				if (orderIndex !== -1) {
					const selectedOrder = this.orders.data[orderIndex]

					// eslint-disable-next-line eqeqeq
					if (this.selectedOrder && this.selectedOrder.id == selectedOrder.id) {
						this.selectOrder(order)
					}

					orders[orderIndex] = order
					this.$set(this.orders, 'data', orders)
				}

				if (['dispatched', 'cancelled', 'completed'].includes(order.state)) {
					this.getOrders({})
					this.getOrderStateAggregate()
					this.updatePendingOrders()
					this.processAutoAcceptedOrders()
				}
			},
			getOrderCancelReasons () {
				this.loading.cancelReasons = true
				this.$store.dispatch('getResource', {
					resource: 'masters',
					params: { master_type: 'admin_cancellation_reason' }
				}).then((response) => {
					this.orderCancellation.reasons = response.data.masters
				}).catch(console.error).finally(() => {
					this.loading.cancelReasons = false
				})
			},
			getDeliveryServices (query) {
				this.loading.deliveryServices = true
				this.$axios.get('/api/online-orders/platforms', {
					params: {
						query,
						platform_type: 'delivery'
					}
				}).then((response) => {
					this.deliveryServices = response.data.data.platforms
						.filter(p => this.selectedOrder.delivery_state !== 'runner_cancelled' ||
							this.selectedOrder.delivery_service.name !== p.slug)
				}).catch(console.error).finally(() => {
					this.loading.deliveryServices = false
				})
			},
			getEmployees (query) {
				this.loading.employees = true
				this.$axios.get('/api/pos/v2/resource/merchant-employees', {
					params: {
						query,
						location_id: this.locationId,
						user_type: 'delivery',
						is_active: 1
					}
				}).then((response) => {
					this.employees = response.data.data.merchant_employees.filter(e => Boolean(e.phone))
				}).catch(console.error).finally(() => {
					this.loading.employees = false
				})
			},
			async setDeliveryOptions ($event) {
				$event.preventDefault()

				if (await this.$refs.deliveryValidator.validate()) {
					this.$refs.statusUpdateBtn?.click()
					this.showDeliveryModal = false
				}
			},
			filterModifiedOrder () {
				this.clearFilters(false)
				this.filters.channel_id = this.selectedOrder.channel_id

				if (this.selectedOrder.parent_pos_order_id) {
					this.filters.order_interim_state = 'cancelled'
				}

				this.getOrders({})
				this.getOrderStateAggregate()
			},
			confirmPayment () {
				this.$refs.statusUpdateBtn?.click()
			},
			printReceipt (order, reprint = false) {
				order.reprint = reprint
				window.printOrder({
					...order,
					payment_method: order.order_payments[0],
					customer: order.customers[0]
				})
			},
			async reprintReceipt () {
				this.printReceipt(await this.formatOnlineOrder(this.selectedOrder.taxable_order || this.selectedOrder), true)
			},
			initReportModal () {
				this.reportFilters.dateRange = {
					startDate: this.dateRange.startDate,
					endDate: this.dateRange.endDate
				}
				this.getReports()
			},
			async getItemsVariations (searchTerm) {
				this.variations = []
				const filters = {
					location_id: this.locationId,
					device_id: this.deviceId,
					query: searchTerm || null,
					category_id: this.reportFilters.category?.id || null
				}

				try {
					const response = await this.$axios.get('/api/pos/inventory', {
						params: filters
					})

					this.variations = []

					response.data.data.inventory.forEach((v) => {
						this.variations.push({
							...v,
							name: v.variationName
						})
					})
				} catch (e) {
					this.$bvToast.toast(this.$t('oopsError.text'), {
						title: this.$t('oopsError.title'),
						toaster: 'b-toaster-bottom-right',
						autoHideDelay: 3000
					})
				}
			},
			searchItemsVariations (searchTerm) {
				clearTimeout(timer)
				timer = setTimeout(() => {
					this.getItemsVariations(searchTerm)
				}, 100)
			},
			async getCategories (searchTerm) {
				const categories = await this.$bridge.getCategories(this.deviceId, this.objToJson({
					merchant_id: this.merchant.id,
					search_term: searchTerm,
					has_item: true,
					offset: this.appVersionNumber >= 4010 ? -1 : undefined
				}))

				this.categories = (typeof categories === 'string' ? JSON.parse(categories) : categories).data
			},
			searchCategories (searchTerm) {
				clearTimeout(timer)
				timer = setTimeout(() => {
					this.getCategories(searchTerm)
				}, 100)
			},
			async getReports (isLoadMore) {
				if (this.type === 'advance') {
					this.loading.report = !(isLoadMore === true)

					try {
						const response = await this.$axios.get('/api/pos/read/items-prepare', {
							params: {
								response: 'json',
								order_source: 'advance',
								status: 'pending',
								category_id: this.reportFilters.category?.id || null,
								location_id: this.locationId,
								item_variation_id: this.reportFilters.variation?.variationId || null,
								scheduled_from_date: this.reportFilters.dateRange.startDate
									? this.$moment(this.reportFilters.dateRange.startDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss')
									: null,
								scheduled_to_date: this.reportFilters.dateRange.endDate
									? this.$moment(this.reportFilters.dateRange.endDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss')
									: null,
								page: isLoadMore === true ? this.items.pagination?.current_page + 1 : null
							}
						})

						if (isLoadMore !== true) {
							this.items = {
								data: []
							}
						}

						if (response.status === 200) {
							response.data.data.forEach((d) => {
								this.items.data.push({
									item_name: d['Variation Name'],
									category: d.Category,
									quantity: d['Items Sold']
								})
							})

							this.items.pagination = response.data.pagination
						}
					} catch (e) {
						this.$bvToast.toast(this.$t('oopsError.text'), {
							title: this.$t('oopsError.title'),
							toaster: 'b-toaster-bottom-right',
							autoHideDelay: 3000
						})
					} finally {
						this.loading.report = false
					}
				}
			},
			loadMoreResults (isVisible) {
				if (isVisible) {
					this.getReports(true)
				}
			},
			resetReportModal () {
				this.loading.report = false
				Object.assign(this.$data, initialState())
			},
			async setPreparationTime (event) {
				event.preventDefault()

				if (await this.$refs.prepTimeValidator.validate()) {
					this.$refs.statusUpdateBtn?.click()
					this.orderPreparation.show = false
				}
			}
		}
	}
