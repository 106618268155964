import { render, staticRenderFns } from "./sell.vue?vue&type=template&id=055e4f20&scoped=true"
import script from "./sell.vue?vue&type=script&lang=js"
export * from "./sell.vue?vue&type=script&lang=js"
import style0 from "./sell.vue?vue&type=style&index=0&id=055e4f20&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "055e4f20",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Items: require('/home/ubuntu/complete-nuxt-js-v410/components/items.vue').default,CustomSale: require('/home/ubuntu/complete-nuxt-js-v410/components/custom-sale.vue').default,GiftCardPurchase: require('/home/ubuntu/complete-nuxt-js-v410/components/gift-card-purchase.vue').default,Cart: require('/home/ubuntu/complete-nuxt-js-v410/components/cart.vue').default,QuantityModal: require('/home/ubuntu/complete-nuxt-js-v410/components/quantity-modal.vue').default,ItemModal: require('/home/ubuntu/complete-nuxt-js-v410/components/item-modal.vue').default,TaxModal: require('/home/ubuntu/complete-nuxt-js-v410/components/tax-modal.vue').default,AdditionalInfoModal: require('/home/ubuntu/complete-nuxt-js-v410/components/additional-info-modal.vue').default,KotCancellationModal: require('/home/ubuntu/complete-nuxt-js-v410/components/kot-cancellation-modal.vue').default,Checkout: require('/home/ubuntu/complete-nuxt-js-v410/components/checkout.vue').default})
